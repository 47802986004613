import React from 'react';
import { Container, Row, Col, Card, ProgressBar } from 'react-bootstrap';

const Progress = () => {
  // Example progress data
  const progressData = [
    { title: 'Course 1', progress: 75 },
    { title: 'Course 2', progress: 50 },
    { title: 'Course 3', progress: 20 },
  ];

  return (
    <Container>
      <h1 className="my-4 text-center">Your Progress</h1>
      <Row>
        {progressData.map((course, index) => (
          <Col md={4} key={index} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{course.title}</Card.Title>
                <ProgressBar now={course.progress} label={`${course.progress}%`} />
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Progress;
