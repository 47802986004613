import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Lessons = () => {
  const lessons = [
    { title: 'Lesson 1', description: 'Introduction to the alphabet.' },
    { title: 'Lesson 2', description: 'Basic phrases in sign language.' },
    { title: 'Lesson 3', description: 'Conversational sign language.' },
  ];

  return (
    <Container>
      <h1 className="my-4 text-center">Lessons</h1>
      <Row>
        {lessons.map((lesson, index) => (
          <Col md={4} key={index} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{lesson.title}</Card.Title>
                <Card.Text>{lesson.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Lessons;
