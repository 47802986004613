import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Card, ListGroup } from 'react-bootstrap';

const Results = () => {
  const location = useLocation();
  const { answers, questions } = location.state || {};

  if (!answers || !questions) {
    return <div>No results to display</div>;
  }

  return (
    <Container>
      <h1 className="my-4 text-center">Your Results</h1>
      {questions.map((question, index) => (
        <Card className="mb-3" key={index}>
          <Card.Body>
            <Card.Title>{question.questionText}</Card.Title>
            <ListGroup>
              <ListGroup.Item>
                <strong>Your answer:</strong> {question.options[answers[index]]}
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
      ))}
    </Container>
  );
};

export default Results;