import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Tests = () => {
  const [exams, setExams] = useState([]);

  useEffect(() => {
    axios.get('http://localhost:8080/api/tests')
      .then(response => {
        setExams(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the tests!', error);
      });
  }, []);

  return (
    <Container>
      <h1 className="my-4 text-center">Available Tests</h1>
      <Row>
        {exams.map((exam, index) => (
          <Col md={4} key={index} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{exam.title}</Card.Title>
                <Button as={Link} to={`/test/${exam.id}`} variant="primary">
                  Take Test
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Tests;
