import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const tiles = [
    { label: 'Courses', path: '/courses' },
    { label: 'Lessons', path: '/lessons' },
    { label: 'Tests', path: '/tests' },
    { label: 'Progress', path: '/progress' },
  ];

  return (
    <Row>
      {tiles.map((tile, index) => (
        <Col md={3} sm={6} key={index} className="mb-4">
          <Card>
            <Card.Body className="text-center">
              <Card.Title>{tile.label}</Card.Title>
              <Link to={tile.path} className="btn btn-primary">
                Go to {tile.label}
              </Link>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default Dashboard;
