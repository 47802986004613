import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Dashboard from '../components/Dashboard';

const Home = () => {
  return (
    <Container>
      <h1 className="my-4 text-center">Welcome to the Sign Language Learning Platform</h1>
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Text>
                This platform will help you learn sign language through interactive lessons and tests.
                Choose a course to get started or take a test to assess your knowledge!
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Dashboard />
    </Container>
  );
};

export default Home;
