import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Courses = () => {
  const courses = [
    { title: 'Course 1', description: 'Learn the basics of sign language.' },
    { title: 'Course 2', description: 'Intermediate sign language skills.' },
    { title: 'Course 3', description: 'Advanced sign language techniques.' },
  ];

  return (
    <Container>
      <h1 className="my-4 text-center">Courses</h1>
      <Row>
        {courses.map((course, index) => (
          <Col md={4} key={index} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{course.title}</Card.Title>
                <Card.Text>{course.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Courses;
